import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuFacade } from '@shared/menu/menu.facade';
import { Order } from '@shared/order';
import { AppState } from '@shared/store';
import { User, UserRootSelectors, UserService } from '@shared/user';
import {Observable} from 'rxjs';
import {getSelectors} from '@ngrx/router-store';
import {map} from 'rxjs/operators';
import {isNewDesignNav, isRedirectToCity, isWhiteDesignNav} from '@shared/navbar/utils';

@Injectable()
export class NavbarFacade {
  public get user$(): Observable<User> {
    return this.userService.profile$;
  }

  public get activeOrder$(): Observable<Order> {
    return this.store.select(UserRootSelectors.activeOrder);
  }

  constructor(
    private store: Store<AppState>,
    private menuFacade: MenuFacade,
    private userService: UserService
  ) { }

  public openMenu(): void {
    this.menuFacade.open();
  }
  public get isWhiteDesignNav$(): Observable<boolean> {
    return this.store.select(getSelectors().selectUrl).pipe(
      map((url) => isWhiteDesignNav(url))
    );
  }

  public get isNewDesignNav$(): Observable<boolean> {
    return this.store.select(getSelectors().selectUrl).pipe(
      map((url) => isNewDesignNav(url))
    );
  }

  public get isRedirectToCity$(): Observable<boolean> {
    return this.store.select(getSelectors().selectUrl).pipe(
      map((url) => isRedirectToCity(url))
    );
  }
}
