import {Actions, createEffect, ofType} from '@ngrx/effects';
import type {AppState} from '@shared/store';
import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {SeoService} from '../seo.service';
import {catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom} from 'rxjs';
import {SeoActions} from './actions';
import {SeoPage, SeoPageHeadingTags, SeoPageService, SeoPageTag, SeoPageTagType, SeoPageType} from '@shared/seo-page';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {getSelectors} from '@ngrx/router-store';
import {ErrorHandlingService} from '@shared/error-handling';

@Injectable()
export class SeoEffects {
  public resetState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SeoActions.resetState),
      tap(() => this.resetSEO())
    ),
    { dispatch: false }
  );

  public loadSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SeoActions.tryLoadSettings),
      tap(() => this.resetSEO()),
      filter(({ request, defaultSettings }) => !!request?.url && (!!request?.type || !!defaultSettings)),
      tap(() => this.store.dispatch(SeoActions.loadSettings())),
      withLatestFrom(
        this.store.select(getSelectors().selectUrl)
      ),
      switchMap(([{ request, onSuccess, onFailure, defaultSettings }, initialURL]) =>
        ((!!request?.type) ? this.seoPageService.getByURL(request) : of(defaultSettings))
          .pipe(
            withLatestFrom(
              this.store.select(getSelectors().selectUrl)
            ),
            filter(([_, currentURL]) => initialURL === currentURL),
            tap(([settings]) => {
              if (settings?.deletedAt && !settings?.isDefault) {
                if(settings.type===SeoPageType.COMMON){
                  throw new HttpErrorResponse({ status: HttpStatusCode.BadRequest });

                }else{
                  throw new HttpErrorResponse({ status: HttpStatusCode.NotFound });

                }
              }
            }),
            tap(([settings]) => {
              if(settings?.deletedAt){
                defaultSettings= {...defaultSettings,codeHead:settings.codeHead+''+defaultSettings.codeHead,isSanDiego:false,isMiamiFlorida:false}
                if (!defaultSettings) {
                  this.seoService.initSettings(defaultSettings);
                }
                onSuccess?.(defaultSettings);
                if(settings.type===SeoPageType.COMMON){
                  throw new HttpErrorResponse({ status: HttpStatusCode.BadRequest });

                }else{
                  throw new HttpErrorResponse({ status: HttpStatusCode.NotFound });

                }
              }else {
                if(request.url==='/services/wash-and-fold-laundry-services/city/san-diego-ca' || request.url==='/services/wash-and-fold-laundry-services/city/miami-fl'){
                  settings.url=request.url;
                  settings=this.initSpecialSetting(settings)
                }
                onSuccess?.(settings);
                this.seoService.initSettings(settings);
              }
            }),
            map(([settings]) => SeoActions.loadSettingsSuccess({ settings })),
            catchError((response: HttpErrorResponse) => {
              if(response.status===HttpStatusCode.NotFound){
                this.errorHandlingService.handleHttpError(response);
              }
              onFailure?.(response);
              if (defaultSettings) {
                this.seoService.initSettings(defaultSettings);
              }
              return of(SeoActions.loadSettingsFailure({ response, settings: defaultSettings }));
            })
          )
      )
    )
  );
    public initSpecialSetting(settings:SeoPage): SeoPage {
      settings.codeHead = settings.isMiamiFlorida ? '<link rel="canonical" href="https://www.hamperapp.com/services/wash-and-fold-laundry-services/city/miami-fl">\n\n<script type="application/ld+json" id="head_2">\n' +
        '{\n' +
        '  "@context": "https://schema.org",\n' +
        '  "@type": "DryCleaningOrLaundry",\n' +
        '  "name": "Hamperapp Laundry Service in Miami, FL",\n' +
        '  "image": "https://storage.googleapis.com/hamperappv2-dev/0_11419800_1706181908_c650794652eb15a2ed7114a05c4581dd.png",\n' +
        '  "@id": "",\n' +
        '  "url": "https://www.hamperapp.com/services/wash-and-fold-laundry-services/city/miami-fl",\n' +
        '  "telephone": "(412) 927-4669",\n' +
        '  "priceRange": "$1.00 - $2.50",\n' +
        '  "description": "Hamperapp offers top-notch wash and fold laundry services in Miami, FL. Convenient, reliable, and affordable laundry solutions for both residential and commercial needs.",\n' +
        '  "servesCuisine": "Laundry",\n' +
        '  "areaServed": {\n' +
        '    "@type": "Place",\n' +
        '    "name": "Miami, FL"\n' +
        '  },\n' +
        '  "aggregateRating": {\n' +
        '    "@type": "AggregateRating",\n' +
        '    "ratingValue": "4.9",\n' +
        '    "bestRating": "5",\n' +
        '    "worstRating": "1",\n' +
        '    "ratingCount": "583006"\n' +
        '  },\n' +
        '  "openingHoursSpecification": {\n' +
        '    "@type": "OpeningHoursSpecification",\n' +
        '    "dayOfWeek": [\n' +
        '      "Monday",\n' +
        '      "Tuesday",\n' +
        '      "Wednesday",\n' +
        '      "Thursday",\n' +
        '      "Friday",\n' +
        '      "Saturday",\n' +
        '      "Sunday"\n' +
        '    ],\n' +
        '    "opens": "07:00",\n' +
        '    "closes": "22:00"\n' +
        '  },\n' +
        '  "sameAs": [\n' +
        '    "https://www.facebook.com/hamperapp",\n' +
        '    "https://twitter.com/hamperapp",\n' +
        '    "https://www.linkedin.com/company/hamperapp"\n' +
        '  ],\n' +
        '  "potentialAction": {\n' +
        '    "@type": "ReserveAction",\n' +
        '    "target": {\n' +
        '      "@type": "EntryPoint",\n' +
        '      "urlTemplate": "https://www.hamperapp.com/register",\n' +
        '      "inLanguage": "en-US",\n' +
        '      "actionPlatform": [\n' +
        '        "http://schema.org/DesktopWebPlatform",\n' +
        '        "http://schema.org/SmartphoneApplication",\n' +
        '        "http://schema.org/TabletWebPlatform"\n' +
        '      ]\n' +
        '    },\n' +
        '    "result": {\n' +
        '      "@type": "Reservation",\n' +
        '      "name": "Laundry Service Reservation"\n' +
        '    }\n' +
        '  }\n' +
        '}\n' +
        '</script>'
        : '<link rel="canonical" href="https://www.hamperapp.com/services/wash-and-fold-laundry-services/city/san-diego-ca"> \n\n<script type="application/ld+json" id="head_2">\n' +
        '{\n' +
        '  "@context": "https://schema.org",\n' +
        '  "@type": "DryCleaningOrLaundry",\n' +
        '  "name": "Hamperapp Laundry Service in San Diego, CA",\n' +
        '  "image": "https://storage.googleapis.com/hamperappv2-dev/0_11419800_1706181908_c650794652eb15a2ed7114a05c4581dd.png",\n' +
        '  "@id": "",\n' +
        '  "url": "https://www.hamperapp.com/services/wash-and-fold-laundry-services/city/san-diego-ca",\n' +
        '  "telephone": "(412) 927-4669",\n' +
        '  "priceRange": "$1.00 - $2.50",\n' +
        '  "description": "Hamperapp offers top-notch wash and fold laundry services in San Diego, CA. Convenient, reliable, and affordable laundry solutions for both residential and commercial needs.",\n' +
        '  "servesCuisine": "Laundry",\n' +
        '  "areaServed": {\n' +
        '    "@type": "Place",\n' +
        '    "name": "San Diego, CA"\n' +
        '  },\n' +
        '  "aggregateRating": {\n' +
        '    "@type": "AggregateRating",\n' +
        '    "ratingValue": "4.9",\n' +
        '    "bestRating": "5",\n' +
        '    "worstRating": "1",\n' +
        '    "ratingCount": "583006"\n' +
        '  },\n' +
        '  "openingHoursSpecification": {\n' +
        '    "@type": "OpeningHoursSpecification",\n' +
        '    "dayOfWeek": [\n' +
        '      "Monday",\n' +
        '      "Tuesday",\n' +
        '      "Wednesday",\n' +
        '      "Thursday",\n' +
        '      "Friday",\n' +
        '      "Saturday",\n' +
        '      "Sunday"\n' +
        '    ],\n' +
        '    "opens": "07:00",\n' +
        '    "closes": "22:00"\n' +
        '  },\n' +
        '  "sameAs": [\n' +
        '    "https://www.facebook.com/hamperapp",\n' +
        '    "https://twitter.com/hamperapp",\n' +
        '    "https://www.linkedin.com/company/hamperapp"\n' +
        '  ],\n' +
        '  "potentialAction": {\n' +
        '    "@type": "ReserveAction",\n' +
        '    "target": {\n' +
        '      "@type": "EntryPoint",\n' +
        '      "urlTemplate": "https://www.hamperapp.com/register",\n' +
        '      "inLanguage": "en-US",\n' +
        '      "actionPlatform": [\n' +
        '        "http://schema.org/DesktopWebPlatform",\n' +
        '        "http://schema.org/SmartphoneApplication",\n' +
        '        "http://schema.org/TabletWebPlatform"\n' +
        '      ]\n' +
        '    },\n' +
        '    "result": {\n' +
        '      "@type": "Reservation",\n' +
        '      "name": "Laundry Service Reservation"\n' +
        '    }\n' +
        '  }\n' +
        '}\n' +
        '</script>'
      // settings.codeHead = settings.isMiamiFlorida ? '' : ''
      // settings.codeHead = settings.isMiamiFlorida ? '' : ''
      settings.socialTags = settings.isMiamiFlorida ? [new SeoPageTag({id: 2123, name: 'og:description', type: SeoPageTagType.SEO, content: 'Hamperapp offers convenient laundry pick-up and delivery in Miami. Schedule online, and we\'ll wash, dry, and fold your clothes for you!'}), new SeoPageTag({id: 2123, name: 'og:title', type: SeoPageTagType.SEO, content: 'Laundry service in Miami, FL - Hamperapp'}), new SeoPageTag({id: 2123, name: 'og:image', type: SeoPageTagType.SEO, content: 'https://storage.googleapis.com/hamperappv2-dev/0_40005900_1713191767_11b84fe0954b36801c05dd6316c8b770.png'})] : [new SeoPageTag({id: 2257, name: 'og:description', type: SeoPageTagType.SEO, content: 'Get your laundry done effortlessly in San Diego with Hamperapp! Wash & Fold service makes laundry a breeze.'}), new SeoPageTag({id: 2256, name: 'og:title', type: SeoPageTagType.SEO, content: 'San Diego\'s Same-Day Laundry Service: Wash, Fold, and Delivered Fast'}), new SeoPageTag({id: 2255, name: 'og:image', type: SeoPageTagType.SEO, content: 'https://storage.googleapis.com/hamperappv2-dev/0_40005900_1713191767_11b84fe0954b36801c05dd6316c8b770.png'})]
      settings.title = settings.isMiamiFlorida ? 'Laundry service in Miami, FL - Hamperapp' : 'Laundry service in San Diego, CA - Hamperapp'
      settings.seoTags = settings.isMiamiFlorida ? [new SeoPageTag({id: 2122, name: 'description', type: SeoPageTagType.SEO, content: 'Hamperapp offers convenient laundry pick-up and delivery in Miami. Schedule online, and we\'ll wash, dry, and fold your clothes for you!'})] : [new SeoPageTag({id:2165, type:SeoPageTagType.SEO, name:'description', content:'Join the laundry revolution in San Diego with Hamperapp! Experience hassle-free laundry service with free pickup & delivery, real-time tracking, and a variety of cleaning options. Schedule your first pickup today and enjoy fresh, clean clothes without lifting a finger. #LaundryServiceSanDiego'})]
      settings.tags.heading1 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'Hamperapp Laundry Service in Miami FL',description:'Discover Hamperapp, the best laundry pick-up and delivery service near you! Miami has so much to offer, from walking along Ocean Drive to exploring Wynwood Arts District or studying at the University of Miami. While you enjoy your day, let Hamperapp handle your laundry.\n \n' +
          'Our laundry pick-up service in Miami makes it easy to schedule a wash and fold with just a few taps. Enter your zip code to check our service area, and we’ll pick up your clothes, wash, dry, and fold them, and deliver them back to you fresh and clean within 24 hours. With our affordable laundry service cost, you can relax while we handle everything, including stain removal!\n'}) : new SeoPageHeadingTags({title:'Laundry service in San Diego, CA',description:'Try Hamperapp, the best laundry service in San Diego, CA! We pick up and deliver your dry cleaning and laundry for free. You can easily set up your service with just a few clicks. Plus, you can track your laundry from when it\'s dirty until it\'s clean, whether you need it every day, once a week, or once a month. \n \n Choose from lots of cleaning options at your local laundromat. Then, sit back and relax. We\'ll bring your clean laundry right to your doorstep. Hamperapp is the most convenient and trustworthy way to do your laundry in San Diego, CA.\n'})
      settings.tags.heading2 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'Miami Laundry Service',description:'In Miami, Hamperapp has the best laundry service with free pickup and delivery near you! No more worrying about crowded laundromats or doing laundry on your own. We make laundry simple by offering convenient laundry and dry cleaning services, including wash and fold in Miami.\n \n' +
          'Hamperapp uses liquid laundry detergent and adjusts the water temperature—whether hot, cold, or cool water—to treat stains and keep your machine washable clothes in top shape. We even handle tough stains with special care using products like hydrogen peroxide. Our laundry pickup service in Miami is fast and reliable, with your clothes washed, dried, and folded within 24 hours.\n \n' +
          'Whether your clothes need a gentle wash in cold water or a deeper clean with hot water, we make sure to use the right method to get them perfectly clean. So, skip the hassle and enjoy the best laundry service with free pickup and delivery—let Hamperapp take care of your laundry while you focus on more important things!\n'}) : new SeoPageHeadingTags({title:'Laundry pick up service in San Diego',description:'Hamperapp is the top choice in San Diego, CA, for getting your laundry done without leaving your house. We work with a bunch of laundry experts all over the city to make sure you can get your laundry done your way. \n \n All you have to do is tap a few buttons on your phone or computer, and we\'ll arrange for someone to pick up your laundry. While you\'re busy with other stuff, our reliable partners will wash, dry, and fold your clothes. You can even watch where your order is in real-time and talk to our customer service any time, day or night. \n \n Trust Hamperapp to bring back your clothes clean, fresh, and neatly folded, right to your doorstep.\n'})
      settings.tags.heading3 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'Experience Clean and Convenient Laundry',description:'Experience hassle-free laundry with Hamperapp in Miami! Say goodbye to laundry day and enjoy the vibrant city instead. Whether you\'re exploring the Art Deco District, relaxing on South Beach, or savoring the flavors of Little Havana, Hamperapp has your laundry needs covered.\n \n' +
          'Our wash and fold service in Miami is the perfect solution for busy days. Simply schedule a pickup, and we’ll take care of the rest!\n \n' +
          'Hamperapp offers convenient laundry pick-up and delivery near you, so you can focus on what really matters. We wash your clothes with care, using cold water for delicate fabrics and handling "dry clean only" items through our professional dry cleaning service. Your clothes will be returned clean, fresh, and perfectly folded.\n \n' +
          'With Hamperapp’s reliable pickup and delivery service, you’ll get your laundry back in no time. Whether it\'s wash and fold or dry cleaning, we ensure your clothes are spotless and ready to wear. Don’t let laundry slow you down—let Hamperapp keep your clothes clean while you enjoy everything Miami has to offer!\n'}) : new SeoPageHeadingTags({title:'Wash and fold laundry service in San Diego',description:'On-demand Laundry service platform, seven days a week. Schedule a pickup Today!'})

      settings.tags.heading4 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'How to find a coin laundry close to me now?',description:'Instead of searching for a coin laundry, try Hamperapp wash and fold service for convenient pickup and delivery. We wash, dry, and fold your clothes so you can enjoy life without the hassle.'}) : new SeoPageHeadingTags({title:'Laundromat delivery service in San Diego CA',description:'If you\'re looking for a laundromat near you in San Diego that also offers free pick-up and delivery, check out Hamperapp! To find out how much it costs for laundry services with Hamperapp in San Diego, CA, it\'s best to go to their website. Once there, just pick your zip code or talk to their friendly customer support team. They will help you know the exact prices for their laundromat delivery service in San Diego.'})
      settings.tags.heading5 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'What does a laundry service do?',description:'A laundry service like Hamperapp washes, dries, and folds your garments with professional care. With our delivery laundry service, you get your laundry cleaned without leaving your home.'}) : new SeoPageHeadingTags({title:'Laundry service delivery and pickup in San Diego',description:'Need to wash your clothes but don\'t want to do it yourself in San Diego? Check out Hamperapp!\n \n' +
          'It\'s a super easy way to get your laundry done without leaving home. All you need to do is tap a few buttons on your phone or computer to start.\n \n' +
          'We work with lots of laundry places in San Diego that we trust. You can choose to have your laundry picked up every day, once a week, or even once a month.\n \n' +
          'Just tell us when to come, and our partners will take care of washing and folding your clothes. You can even watch where your laundry is on its journey back to you.\n \n' +
          'Plus, if you need any help, our team is here to answer your questions any time, day or night. When your clothes are clean, we\'ll bring them straight to your doorstep. Give Hamperapp a try for the easiest way to handle your laundry in San Diego!\n'})
      settings.tags.heading6 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'How do laundry services tag customer clothes?',description:'Hamperapp laundry service tags each customer\'s clothes with unique identifiers to ensure proper tracking and return. You\'ll receive updates via email or text message depending on your preference.'}) : new SeoPageHeadingTags({title:'Pick Up Laundry Service San Diego',description:'Looking for a way to get your laundry done without any hassle in San Diego? Say hello to Hamperapp! We make washing your clothes easy and quick. Just use your phone or computer to tell us when you need your laundry picked up. We work with the best laundromats around town, so you can trust your clothes will be in good hands.\n \n' +
          'No matter if you need your clothes washed once a day, once a week, or once a month, we can help. Just schedule a pick-up for a time that works for you. Our partners will take it from there. You can even watch where your clothes are in the cleaning process with our real-time tracking. And if you have any questions, our customer service team is here to help 24/7.\n \n' +
          'When your clothes are clean, dry, and folded neatly, we\'ll bring them straight to your doorstep. Plus, we make sure to work quickly, so you\'ll get everything back in just a business day or two. Try out Hamperapp today for an easy way to take care of your laundry in San Diego!\n'})
      settings.tags.heading7 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'How to do laundry while traveling?',description:'Use Hamperapp laundry service to take care of your laundry while you travel. We provide pickup and delivery in Miami and nearby areas. Simply schedule a pickup, and we\'ll wash, dry, and fold your clothes so you can enjoy life.'}) : new SeoPageHeadingTags({title:'Can I Trust a Laundry Service with My Delicate Items?',description:'Absolutely! Hamperapp understands the importance of handling delicate items with care. Our experienced team uses specialized techniques and detergents to ensure your precious garments are treated with the utmost care.\n'})
      settings.tags.heading8 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'How to find the best laundry services around you?',description:'Try Hamperapp laundry service for reliable and professional laundry cleaning. We offer delivery laundry service, so you can enjoy life without worrying about laundry chores.'}) : new SeoPageHeadingTags({title:'How Can I Find a Laundry Service That Offers Flexible Scheduling?',description:'Hamperapp offers flexible scheduling options to accommodate your busy lifestyle. Whether you need a weekly pickup or a one-time service, we can tailor our services to meet your specific needs.\n'})
      settings.tags.heading9 = settings.isMiamiFlorida ? new SeoPageHeadingTags({title:'Where can I find a professional laundry service?',description:'Look no further than Hamperapp laundry service for professional care of your garments. We provide washing, drying, and folding with convenient pickup and delivery.'}) : new SeoPageHeadingTags({title:'Is There a Laundry Service That Provides Excellent Customer Service?',description:'Hamperapp prides itself on providing exceptional customer service. Our friendly and responsive team is always available to answer your questions and ensure your satisfaction. We believe in building long-lasting relationships with our customers, making Hamperapp your trusted laundry partner in San Diego, CA.'})

      if (settings.isMiamiFlorida) {
        settings.tags.heading10 = new SeoPageHeadingTags({title:'Are laundry services worth it?',description:'Yes, using a service like Hamperapp allows you to enjoy life without spending time on laundry chores. Our delivery laundry service ensures that we professionally clean and handle your clothes.'})
        settings.tags.heading11 = new SeoPageHeadingTags({title:'What are the advantages of wash and fold laundry services?',description:'Wash and fold services like Hamperapp save you time by professionally washing, drying, and folding your laundry. With our delivery service, you can focus on what matters most.'})
        settings.tags.heading12 = new SeoPageHeadingTags({title:'How much does it cost to have someone do your laundry?',description:'Hamperapp laundry service offers competitive pricing based on weight or item count. Check our website for rates and enjoy the convenience of our delivery laundry service.'})
        settings.tags.heading13 = new SeoPageHeadingTags({title:'Which is better: dry cleaning or laundry?',description:'It depends on the fabric and care instructions; Hamperapp offers both dry cleaning and laundry services to meet your needs. We\'ll ensure that we clean your clothes properly using the best method for each item.'})
      }
      return settings;
  }
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private seoService: SeoService,
    private seoPageService: SeoPageService,
    private errorHandlingService: ErrorHandlingService,
  ) { }

  private resetSEO(): void {
    this.seoService.reset();
  }
}
