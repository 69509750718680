export const isWhiteDesignNav = (url: string): boolean => {
  return   url?.startsWith('/locations');
}

export const isRedirectToCity = (url: string): boolean => {
  return   url?.startsWith('/state') || url?.startsWith('/services/city');
}

export const isNewDesignNav = (url: string): boolean => {
  return url==='/';
}
